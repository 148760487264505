import _validator from "./validator";
import _XMLParser from "./xmlparser/XMLParser";
import _json2xml from "./xmlbuilder/json2xml";
var exports = {};
const validator = _validator;
const XMLParser = _XMLParser;
const XMLBuilder = _json2xml;
exports = {
  XMLParser: XMLParser,
  XMLValidator: validator,
  XMLBuilder: XMLBuilder
};
export default exports;
export const XMLValidator = exports.XMLValidator;
const _XMLParser2 = exports.XMLParser,
  _XMLBuilder = exports.XMLBuilder;
export { _XMLParser2 as XMLParser, _XMLBuilder as XMLBuilder };